import {
  getCumulativeVCScaleX,
  getCumulativeVCScaleY,
  getScaledManualHorizontalPadding,
  getScaledManualLeftPadding,
  getScaledManualTopPadding,
  getScaledManualVerticalPadding,
} from '@PosterWhiteboard/items/item/item.library';
import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {ItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {getBoldStrokeWidth, validateText} from '@PosterWhiteboard/libraries/text.library';
import type {OnResizeParams} from '@PosterWhiteboard/poster/poster-item-controls';
import {getPmwMbControl, getPmwMtControl, getPmwMlControl, getPmwMrControl, ITEM_CONTROL_DIMENSIONS} from '@PosterWhiteboard/poster/poster-item-controls';
import type {Point} from '@Utils/math.util';
import {degreesToRadians, integerToRoman} from '@Utils/math.util';
import type {RGB} from '@Utils/color.util';
import {rgbToHex, rgbToHexString} from '@Utils/color.util';
import type {VectorItem} from '@PosterWhiteboard/items/vector-item/vector-item.class';
import {hexToAscii} from '@Utils/string.util';
import {getListTypeFromPastedText, LIST_TYPES, ORDERED_LIST_STYLE, TextList, UNORDERED_BULLETS_ASCII_MAP, UNORDERED_LIST_STYLE} from '@PosterWhiteboard/items/text-item/text-list';
import type {TextItemObject} from '@PosterWhiteboard/items/text-item/text-item.types';
import {DEFAULT_TEXT_SHADOW_BLUR, DEFAULT_TEXT_SHADOW_DISTANCE} from '@PosterWhiteboard/items/text-item/text-item.types';
import {getIsSpellCheckEnabledFromStore} from '@Libraries/spell-check-library';
import type {VectorItemObject} from '@PosterWhiteboard/items/vector-item/vector-item.types';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {
  DEFAULT_FONT_STYLE,
  DEFAULT_STROKE_WIDTH,
  DEFAULT_TEXT_FONT_FAMILY,
  TEXT_OUTLINE_STROKE_WIDTH_FACTOR,
  TextHorizontalAlignType,
  TextStyles,
  TextVerticalAlignType,
} from '@PosterWhiteboard/classes/text-styles.class';
import {FillTypes, getLinearGradientOpts, getRadialGradientOpts} from '@PosterWhiteboard/classes/fill.class';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import {getIsClipboardReadSupported} from '@Libraries/clipboard-library';
import {getValidatedItemsAndPosterClipboardData} from '@PosterWhiteboard/page/page-clipboard';
import {closeTextSelectionPopup, handleTextSelectionPopupMenu, shouldShowTextSelectionPopup} from '@Libraries/text-selection-library';
import {POSTER_VERSION} from '@PosterWhiteboard/poster/poster.types';
import {fontSupportsText} from '@Utils/font.util';
import {ElementDataType} from '@Libraries/add-media-library';
import {isMobile} from 'react-device-detect';
import {VectorItemSource} from '@PosterWhiteboard/items/vector-item/vector-item.library';
import {createItemFromObject} from '@PosterWhiteboard/items/item/item-factory';
import {readFromClipboard} from '@Utils/clipboard.util';
import type {FabricObject, ObjectEvents, TPointerEvent, Point as FabricPoint, TOriginY, TFiller} from '@postermywall/fabricjs-2';
import {LayoutManager, FixedLayout, config, Canvas, Gradient, Group, Textbox, util} from '@postermywall/fabricjs-2';
import {addItemsToGroupWithOriginalScale} from '@Utils/fabric.util';
import {ItemAura} from '@PosterWhiteboard/classes/item-aura.class';
import type {DeepPartial} from '@/global';
import {
  addFonts,
  BULLETS_FONT_FAMILY,
  FONT_LOAD_STATUS,
  FONT_VARIATION,
  fontsRequestedMap,
  getFontFamilyNameForVariations,
  isBoldVariationAvaliableForFont,
  isInternalFont,
  isItalicVariationAvaliableForFont,
  loadBulletsFont,
} from '@/libraries/font-library';

const DEFAULT_FONT_SIZE_FRACTION = 0.18;
export const DEFAULT_CACHE_EXPANSION_FACTOR = 1;
export const CUSTOM_FONT_CACHE_EXPANSION_FACTOR = 1.8;
export const MAX_CHARACTERS_LIMIT = '10000';
const DEFAULT_BACKGROUND_SHAPE_ID = '804';
const DEFAULT_BACKGROUND_COLOR: RGB = [184, 184, 184];
const VERSION1_SELECTOR_PADDING = 11;
const unsupportedFonts = ['BermudaSquiggle', 'PrincessSofiaRegular', 'Bevan'];
const PIXEL_TARGET_FIND_TOLERANCE = 10;
const MOBILE_PIXEL_TARGET_FIND_TOLERANCE = 50;

export enum TextVersion {
  TEXT_VERSION_1 = 1,
  TEXT_VERSION_2 = 2,
  TEXT_VERSION_3 = 3,
}

interface ClonePositionalParams {
  left: number;
  top: number;
  originY?: TOriginY;
}

export interface FabricItemDimensions {
  width: number;
  height: number;
}

interface ItemCoordinates {
  x?: number;
  y?: number;
}

const TOTAL_ALPHA_COUNT = 26;
const BULLETS_TOP_DISTANCE_FACTOR = 16;
const BULLETS_RIGHT_DISTANCE_FACTOR = 4;

/**
 * saves the list style from last pasted text
 */
let pastedTextListType = LIST_TYPES.NONE;

export class TextItem extends Item {
  declare fabricObject: Group;

  public gitype = ITEM_TYPE.TEXT;
  public text = '';
  public backgroundType = 0;
  public backgroundColor: RGB = [184, 184, 184];
  public backgroundColorAlpha = 1;
  public editable = true;
  public baseWidth = 0;
  public fontSize = 0;
  public wrappedLines = [];
  public verticalAlign: TextVerticalAlignType = TextVerticalAlignType.TOP;
  public verticalPadding = 0;
  public background!: VectorItem;
  public list: TextList;
  public fabricBulletTextbox: Textbox | null = null;
  public version = TextVersion.TEXT_VERSION_3;
  public fabricTextbox!: Textbox;
  public clone: Textbox | null = null;
  public textChangedTimeout = 0;
  public textStyles: TextStyles;
  public enterEditModeOnMouseUp = true;

  constructor(page: Page) {
    super(page);
    this.textStyles = new TextStyles();
    this.list = new TextList({});
    this.aura = new ItemAura(DEFAULT_TEXT_SHADOW_DISTANCE, DEFAULT_TEXT_SHADOW_BLUR);
  }

  protected fixChanges(): void {
    this.text = validateText(this.text);
    super.fixChanges();
    this.applyFixForUnsupportedFonts();
    this.fixVersioningChanges();
    this.updateBaseWidth();
  }

  public fixVersioningChanges(): void {
    this.applyFixForVersion3();
    if (this.page.poster.version < POSTER_VERSION.HTML5 && this.textStyles.letterSpacing > 0) {
      this.width += this.textStyles.letterSpacing;
    }
    if (this.page.poster.version < POSTER_VERSION.LEGACY) {
      this.textStyles.leading = 120;
    }
  }

  public async updateFromObject(textItemObject: DeepPartial<TextItemObject>, {updateRedux = true, undoable = true, doInvalidate = true}: UpdateFromObjectOpts = {}): Promise<void> {
    const {background, ...obj} = textItemObject;
    this.copyVals(obj);
    await this.ensureFontsAreLoaded();

    if (background !== undefined) {
      if (this.background) {
        this.background.copyVals(background);
      } else {
        // TODO: Added width = 1 because isNew flag in vector-item assumes it's a new vector and feeds it strokeWidth > 0 (vector.border.solidBorderThickness on item level), while it should be 0 for background vector (we're setting it 0 in getDefaultBackgroundProperties()).
        this.background = (await createItemFromObject(this.page, {
          ...this.getDefaultBackgroundProperties(),
          ...background,
          width: 1,
        })) as VectorItem;
      }
    } else if (!this.background) {
      // TODO: Added width = 1 because isNew flag in vector-item assumes it's a new vector and feeds it strokeWidth > 0 (vector.border.solidBorderThickness on item level), while it should be 0 for background vector (we're setting it 0 in getDefaultBackgroundProperties()).
      this.background = (await createItemFromObject(this.page, {
        ...this.getDefaultBackgroundProperties(),
        width: 1,
      })) as VectorItem;
    }

    await this.init();

    if (doInvalidate) {
      await this.invalidate();
    }

    if (undoable) {
      this.page.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.page.poster.redux.updateReduxData();
    }
  }

  public copyVals(obj: DeepPartial<TextItemObject>): void {
    const {textStyles, list, background, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.textStyles.copyVals(textStyles);
    this.list.copyVals(list);
  }

  private async ensureFontsAreLoaded(): Promise<void> {
    await this.checkForFontsLoad();
    await this.checkForBulletsFontLoad();
  }

  private updateBaseWidth(): void {
    if (this.baseWidth === 0) {
      if (this.width === 0) {
        this.baseWidth = this.textStyles.fontSize * (this.text.length / 2);
        this.width = this.baseWidth + this.getManualSelectorPadding() * 2 + DEFAULT_STROKE_WIDTH;
      } else {
        this.baseWidth = this.calculateBaseWidthFromWidth();
      }
    }
  }

  public applyFixForVersion3(): void {
    if (this.isTextVersion1()) {
      // Adding strokeWidth to dimensions because the new text item is a fabric.Group item and doesn't have strokeWidth unlike previous item which was Textbox.
      this.width = Number(this.width) + getScaledManualHorizontalPadding(this.scaleX) + DEFAULT_STROKE_WIDTH;
      this.height = Number(this.height) + getScaledManualVerticalPadding(this.scaleY) + DEFAULT_STROKE_WIDTH;
      this.x -= this.getHorizontalDisplacementForVersion2();
      this.y -= this.getVerticalDisplacementForVersion2();
      this.version = TextVersion.TEXT_VERSION_2;
    }

    if (this.isTextVersion2()) {
      this.baseWidth = this.calculateBaseWidthFromWidth();
      this.version = TextVersion.TEXT_VERSION_3;
    }
  }

  private calculateBaseWidthFromWidth(): number {
    if (this.width) {
      return this.width - getScaledManualHorizontalPadding(this.scaleX) - this.getStrokeWidth() - (this.list ? this.list.width : 0);
    }

    return 0;
  }

  protected applyFixForUnsupportedFonts(): void {
    // Reset an unsupported font to the default one
    if (unsupportedFonts.indexOf(this.textStyles.fontFamily) !== -1) {
      this.textStyles.fontFamily = DEFAULT_TEXT_FONT_FAMILY;
    }

    // Difference in font name in Flash and HTML5.
    if (this.textStyles.fontFamily === 'MountainsOfChristmas') {
      this.textStyles.fontFamily = 'MountainsofChristmas';
    }
  }

  protected hasOldVersionBackground(): boolean {
    return this.backgroundType === 1;
  }

  protected isTextVersion1(): boolean {
    return this.version === TextVersion.TEXT_VERSION_1;
  }

  protected isTextVersion2(): boolean {
    return this.version === TextVersion.TEXT_VERSION_2;
  }

  public isTextEmpty(): boolean {
    return this.text === '';
  }

  public getHorizontalDisplacementForVersion2(): number {
    return Math.sqrt(2 * VERSION1_SELECTOR_PADDING ** 2) * Math.cos(util.degreesToRadians(45 + this.rotation));
  }

  public getVerticalDisplacementForVersion2(): number {
    return Math.sqrt(2 * VERSION1_SELECTOR_PADDING ** 2) * Math.sin(util.degreesToRadians(45 + this.rotation));
  }

  public getFonts(withVariation: boolean): Array<string> {
    return [withVariation ? getFontFamilyNameForVariations(this.textStyles.fontFamily, this.textStyles.isBold, this.textStyles.isItalic) : this.textStyles.fontFamily];
  }

  protected async hasUnsupportedText(): Promise<boolean> {
    const supported = this.getFonts(true).map((family) => {
      return fontSupportsText(family, this.text);
    });

    const hasSupportedTextArray = await Promise.all(supported);
    return hasSupportedTextArray.some((isSupported) => {
      return !isSupported;
    });
  }

  public hasBackground(): boolean {
    return this.background?.fill.hasFill();
  }

  public async isPDFSafe(): Promise<boolean> {
    // This font fails to register in pdf. Investigate later why
    if (['SANTORegular', 'BarrioRegular'].includes(this.textStyles.fontFamily)) {
      return false;
    }

    try {
      const RTLScripts = ['arabic', 'hebrew'];
      const incompatibleFont = await this.hasUnsupportedText();
      const incompatibleDecorations =
        (this.textStyles.letterSpacing > 0 && (this.textStyles.underLine || this.textStyles.lineThrough)) || // fabricJS breaks decoration on individual characters
        (this.textStyles.lineThrough && this.textStyles.underLine) ||
        this.background.fill.fillType === FillTypes.RADIAL_GRADIENT ||
        this.textStyles.stroke; // bug in svg-to-pdfkit does not treat decorations as a list, so need to rasterize if using both together

      return !this.aura.hasAura() && !this.list.isUnordered() && !RTLScripts.includes(this.textStyles.script) && !incompatibleFont && !incompatibleDecorations;
    } catch (_) {
      return false;
    }
  }

  protected hasCenterHorizontalAlignment(): boolean {
    return this.textStyles.textAlign === TextHorizontalAlignType.CENTER;
  }

  protected hasRightHorizontalAlignment(): boolean {
    return this.textStyles.textAlign === TextHorizontalAlignType.RIGHT;
  }

  private getDefaultBackgroundProperties(): DeepPartial<VectorItemObject | ItemObject> {
    return {
      gitype: ITEM_TYPE.VECTOR,
      fileName: DEFAULT_BACKGROUND_SHAPE_ID,
      source: VectorItemSource.PMW_SHAPES,
      fill: {
        fillType: FillTypes.NONE,
        fillColor: [DEFAULT_BACKGROUND_COLOR],
      },
      border: {
        solidBorderThickness: 0,
      },
    };
  }

  public toObject(): TextItemObject {
    return {
      ...super.toObject(),
      textStyles: this.textStyles.toObject(),
      baseWidth: this.baseWidth,
      fontFamily: this.textStyles.fontFamily,
      wrappedLines: this.wrappedLines,
      verticalAlign: this.verticalAlign,
      verticalPadding: this.verticalPadding,
      background: this.background.toObject(),
      list: this.list.toObject(),
      text: this.text,
      backgroundType: this.backgroundType,
      backgroundColor: this.backgroundColor,
      backgroundColorAlpha: this.backgroundColorAlpha,
    };
  }

  public async initFabricObject(): Promise<void> {
    return new Promise((resolve, reject) => {
      addFonts(
        [this.textStyles.getFontFamilyToLoad()],
        async () => {
          await super.initFabricObject();
          this.addBackgroundToGroup();
          resolve();
        },
        reject
      );
    });
  }

  protected addBackgroundToGroup(): void {
    this.setBackgroundParams();
    this.fabricObject.insertAt(0, this.background.fabricObject as FabricObject);
  }

  public setBackgroundParams(): void {
    this.background?.fabricObject.set({
      left: -this.fabricObject.width / 2,
      top: -this.fabricObject.height / 2,
      scaleX: this.fabricObject.width / this.background.fabricObject.width,
      scaleY: this.fabricObject.height / this.background.fabricObject.height,
    });
  }

  public async init(): Promise<void> {
    if (!this.isInitialzed) {
      this.fixChanges();
      await this.initList();
      this.beforeInitFabricObject();
      await this.initFabricObject();
      this.onInitItem();
      this.isInitialzed = true;
    }
  }

  protected async initList(): Promise<void> {
    this.list = this.list
      ? new TextList({
          fill: this.list.fill,
          type: this.list.type,
          style: this.list.style,
          width: this.list.width,
        })
      : new TextList({});

    if (this.list.hasList()) {
      return new Promise<void>((resolve) => {
        loadBulletsFont(BULLETS_FONT_FAMILY, () => {
          resolve();
        });
      });
    }

    return new Promise<void>((resolve) => {
      resolve();
    });
  }

  public getBaseWidth(): number {
    const scaleX = getCumulativeVCScaleX(this.fabricObject);
    return this.fabricObject.width - this.getBulletsWidth() - getScaledManualHorizontalPadding(scaleX) - this.getStrokeWidth();
  }

  protected upgradeBackground(): void {
    if (this.background) {
      this.background.fill.fillType = FillTypes.NONE;
      this.background.fill.fillColor[0] = this.backgroundColor;
      this.background.fill.fillAlpha = this.backgroundColorAlpha;
    }
  }

  public onScaling(): void {
    this.updateFabricGroupHeight();
    this.updateFabricGroupWidth();
    this.setBackgroundParams();
    this.setTextChildPosition();
    this.loading.updateTextOnItemResizeAndRender();
  }

  protected initEvents(): void {
    super.initEvents();
    this.fabricObject.on('mousedown:before', this.onMouseDownBefore.bind(this));
    this.fabricObject.on('mouseup', this.onMouseUp.bind(this));
    this.fabricObject.on('mouseup:before', this.onMouseUpBefore.bind(this));

    // TODO: Do this when working on spell check.
    this.fabricObject.on('deselected', this.onDeselected.bind(this));
  }

  protected onDeselected(): void {
    // TODO: When generation/view page flags are set
    // if (!postermywall.Core.isPosterGenerating && !postermywall.Core.isViewPage) {
    this.page?.spellCheck?.clearSquigglyLineStyle(this.fabricTextbox);
    // }
  }

  protected async getFabricObjectForItem(): Promise<Group> {
    return new Promise((resolve) => {
      this.fabricTextbox = new Textbox(this.text, {
        ...this.textStyles.getTextStyles(this.baseWidth, 100),
        statefullCache: true,
        width: this.baseWidth,
        perPixelTargetFind: true,
        // @ts-expect-error TODO: Remove this later.
        _fontSizeFraction: FONTSIZE_FRACTION[this.textStyles.fontFamily] ?? DEFAULT_FONT_SIZE_FRACTION,
      });

      const groupItem = new Group([this.fabricTextbox], {
        ...super.getCommonOptions(),
        width: this.calculateGroupWidth(),
        height: this.calculateGroupHeight(),
        useSelectedFlag: true,
        selected: false,
        caterCacheForTextChildren: true,
        perPixelTargetFind: true,
        layoutManager: new LayoutManager(new FixedLayout()),
      });

      const pmwMlControl = getPmwMlControl(this.onResizeWithLeftHandle.bind(this));
      const pmwMrControl = getPmwMrControl(this.onResizeWithRightHandle.bind(this));
      const pmwMtControl = getPmwMtControl(this.onResizeWithTopHandle.bind(this));
      const pmwMbControl = getPmwMbControl(this.onResizeWithBottomHandle.bind(this));
      groupItem.controls[pmwMlControl.key] = pmwMlControl.control;
      groupItem.controls[pmwMrControl.key] = pmwMrControl.control;
      groupItem.controls[pmwMtControl.key] = pmwMtControl.control;
      groupItem.controls[pmwMbControl.key] = pmwMbControl.control;

      resolve(groupItem);
    });
  }

  private getPixelTargetFindTolerance(): number {
    return isMobile ? MOBILE_PIXEL_TARGET_FIND_TOLERANCE : PIXEL_TARGET_FIND_TOLERANCE;
  }

  public async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    await this.background.updateFabricObject();
    this.fabricObject.set(this.getOptionsForGroupItem());
    this.fabricTextbox.set(this.getOptionsForTextItem());
    this.fabricTextbox.set({
      ...this.textStyles.getTextStyles(this.fabricTextbox.width, this.fabricTextbox.height),
      // Not applying width here breaks undo for text item width not getting restored for font size that causes different text item width (rev. 62225) (has to do with the order in which styles are applied to fabric textbox).
      text: this.text,
      width: this.baseWidth,
    });
    // have to reapply text item fontStyle because it gives bad fabric Textbox height otherwise (Rev. 63548) (has to do with the order in which styles are applied to fabric textbox).
    this.fabricTextbox.set('fontStyle', this.textStyles.getFontStyle());

    if (this.fabricTextbox.hiddenTextarea) {
      this.fabricTextbox.hiddenTextarea.value = this.text;
    }

    this.applyTextStroke(this.fabricTextbox);
    this.setBulletPoints();
    // TODO: Check if these are needed when testing modifications done to text from sidebar options (font size etc)
    // The dimensions are set again just in case application of bullets or other modifications from sidebar options (font size etc), modify the width.
    this.updateFabricGroupHeight();
    this.updateFabricGroupWidth();
    this.setBulletsPosition();

    this.setBackgroundParams();
    this.setCloneProperties();
    this.setTextChildPosition();
    // if (!postermywall.Core.isPosterGenerating && !postermywall.Core.isViewPage) {
    if (this.clone) {
      this.page?.spellCheck?.checkSpell(this.clone, true).catch(() => {});
    }
    this.fabricObject.setCoords();
    // }
  }

  protected checkForFontsLoad(): Promise<void> {
    return new Promise((resolve, reject) => {
      addFonts([this.textStyles.getFontFamilyToLoad()], resolve, reject);
    });
  }

  protected async checkForBulletsFontLoad(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.list.hasList()) {
        loadBulletsFont(BULLETS_FONT_FAMILY, () => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  public getActiveText(): string {
    return this.text;
  }

  protected getOptionsForGroupItem(): Record<string, any> {
    return {
      width: this.width,
      height: this.height,
    };
  }

  protected getOptionsForTextItem(): Record<string, any> {
    return {
      text: this.text,
      shadow: this.getShadow(),
      cursorWidth: this.editable ? 2 : 0,
      width: this.baseWidth,
      opacity: this.clone ? 0 : 1,
      cursorColor: this.getCursorColor(),
      _fontSizeFraction:
        this.textStyles.fontFamily in FONTSIZE_FRACTION ? FONTSIZE_FRACTION[this.textStyles.fontFamily as keyof typeof FONTSIZE_FRACTION] : DEFAULT_FONT_SIZE_FRACTION,
      cacheExpansionFactor: this.getCacheExpansionFactor(),
    };
  }

  private getCacheExpansionFactor(): number {
    if (this.textStyles.fontFamily in CUSTOM_CACHE_EXPANSION_FACTOR) {
      return CUSTOM_CACHE_EXPANSION_FACTOR[this.textStyles.fontFamily as keyof typeof CUSTOM_CACHE_EXPANSION_FACTOR];
    }
    if (!isInternalFont(this.textStyles.fontFamily)) {
      return CUSTOM_FONT_CACHE_EXPANSION_FACTOR;
    }
    return DEFAULT_CACHE_EXPANSION_FACTOR;
  }

  protected applyShadowToFabricObject(): void {}

  public getCursorColor(): string {
    return `#${rgbToHex(this.textStyles.fill.fillColor[0])}`;
  }

  protected onFabricObjectModified(): void {
    this.updateFromObject({
      ...this.getValuesOnFabricObjectModified(),
      baseWidth: this.fabricTextbox.width,
      verticalPadding: this.getVerticalPadding(),
    }).catch((e) => {
      console.error(`Failed to update item on fabric object modified, Details:${JSON.stringify(e)}`);
    });
  }

  protected getScaleForShadow(): number {
    return 1;
  }

  protected getOldShadowDistance(): number {
    return Math.max(1, Math.floor(this.textStyles.fontSize / 14));
  }

  public applyTextStroke(fabricTextboxItem: Textbox): void {
    if (this.textStyles.stroke) {
      fabricTextboxItem.set('strokeWidth', this.textStyles.fontSize * this.textStyles.strokeWidth * TEXT_OUTLINE_STROKE_WIDTH_FACTOR);
      fabricTextboxItem.set('strokeLineJoin', 'round');
      fabricTextboxItem.set('paintFirst', 'stroke');
      fabricTextboxItem.set('stroke', rgbToHexString(this.textStyles.strokeColor, 1));
    } else if (!this.textStyles.isBold) {
      fabricTextboxItem.set('strokeWidth', DEFAULT_STROKE_WIDTH);
      fabricTextboxItem.set('strokeLineJoin', 'miter');
      fabricTextboxItem.set('paintFirst', 'fill');
      fabricTextboxItem.set('stroke', undefined);
    }
  }

  protected setControlsVisibility(): void {
    super.setControlsVisibility();
    const isItemLocked = this.isLocked();
    this.fabricObject.setControlsVisibility({
      pmwMr: !isItemLocked,
      pmwMl: !isItemLocked,
      pmwMt: !isItemLocked,
      pmwMb: !isItemLocked,
    });
  }

  protected onMouseDownBefore(): void {
    if (this.fabricObject === this.page.activeSelection.getActiveObject()) {
      this.beforeEnterEditMode();
    }
  }

  // TODO: change the type when migrated to fabric v6.
  private onMouseUp(options: ObjectEvents['mouseup']): void {
    // Don't enter the editing mode if the mouse up event happened because of an action performed by control button
    const mouseEvent = options.e as MouseEvent;
    if (!this.enterEditModeOnMouseUp || (options.transform && options.transform.actionPerformed) || (mouseEvent.button && mouseEvent.button !== 1)) {
      this.enterEditModeOnMouseUp = true;
      return;
    }

    this.onEnterEditMode(options.e);
  }

  private onMouseUpBefore(options: ObjectEvents['mouseup:before']): void {
    if (this.page.longPressInitiated || config.isCanvasTwoFingerPanning || options.target?.__corner) {
      this.enterEditModeOnMouseUp = false;
    }
  }

  public enterEditMode(e: MouseEvent): void {
    this.beforeEnterEditMode();
    this.onEnterEditMode(e);
  }

  protected beforeEnterEditMode(): void {
    this.fabricObject.selected = true;
  }

  protected onEnterEditMode(e: TPointerEvent): void {
    const cloneExists = !!this.clone;
    if (!cloneExists && this.fabricObject.selected && this.editable) {
      this.enterCloneEditMode(e);

      this.clone?.selectAll();
    }
  }

  /**
   * Handler for adjusting width of item using right handle
   * @see https://docs.google.com/document/d/1G53Y_S7OlikrEUskOykiCJ7826nhoNNOWl-53AWSAqU/edit?usp=sharing
   */
  protected onResizeWithRightHandle(event: OnResizeParams): void {
    const newWidth = this.fabricObject.width + event.delta / this.fabricObject.scaleX;
    if (newWidth < this.getMinWidth()) {
      return;
    }

    // CodeReviewDany: I don't see setWdith in Group code. Revisit this when fabric is updated.
    this.fabricObject.set({width: newWidth});
    this.onModifyWidth();
  }

  /**
   * Handler for adjusting width of item using left handle
   * @see https://docs.google.com/document/d/1G53Y_S7OlikrEUskOykiCJ7826nhoNNOWl-53AWSAqU/edit?usp=sharing
   */
  private onResizeWithLeftHandle(event: OnResizeParams): void {
    const newWidth = this.fabricObject.width + event.delta / this.fabricObject.scaleX;
    if (newWidth < this.getMinWidth()) {
      return;
    }

    this.fabricObject.set({
      width: newWidth,
      left: this.fabricObject.left - event.delta * Math.cos(degreesToRadians(this.fabricObject.angle)),
      top: this.fabricObject.top - event.delta * Math.sin(degreesToRadians(this.fabricObject.angle)),
    });
    this.onModifyWidth();
  }

  /**
   * Handler for adjusting height of item using bottom handle
   * @see https://docs.google.com/document/d/1G53Y_S7OlikrEUskOykiCJ7826nhoNNOWl-53AWSAqU/edit?usp=sharing
   */
  private onResizeWithBottomHandle(event: OnResizeParams): void {
    const newHeight = this.fabricObject.height + event.delta / this.fabricObject.scaleY;
    if (newHeight < this.getMinHeight()) {
      return;
    }

    this.fabricObject.set({height: newHeight});
    this.onModifyHeight();
  }

  /**
   * Handler for adjusting height of item using top handle
   * @see https://docs.google.com/document/d/1G53Y_S7OlikrEUskOykiCJ7826nhoNNOWl-53AWSAqU/edit?usp=sharing
   */
  private onResizeWithTopHandle(event: OnResizeParams): void {
    const newHeight = this.fabricObject.height + event.delta / this.fabricObject.scaleY;
    if (newHeight < this.getMinHeight()) {
      return;
    }

    this.fabricObject.set({
      height: newHeight,
      left: this.fabricObject.left + event.delta * Math.sin(degreesToRadians(this.fabricObject.angle)),
      top: this.fabricObject.top - event.delta * Math.cos(degreesToRadians(this.fabricObject.angle)),
    });

    this.onModifyHeight();
  }

  private enterCloneEditMode(e: TPointerEvent): void {
    const canvas = this.page.fabricCanvas;
    const index = canvas.getObjects().indexOf(this.fabricObject);

    this.clone = new Textbox(this.fabricTextbox.text ?? '', {});
    this.clone.__PMWID = this.uid;
    this.clone.padding = 0;

    this.fabricTextbox.set('opacity', 0);
    canvas.add(this.clone);
    canvas.moveObjectTo(this.clone, index + 1);
    if (canvas instanceof Canvas) {
      canvas.setActiveObject(this.clone);
    }

    this.clone.on('editing:entered', this.onTextEditModeEntered.bind(this));
    this.clone.on('editing:exited', this.onEditingExited.bind(this));

    this.clone.enterEditing(e);
    this.setCloneProperties();
    this.page.canvasPanOnTextEdit.beforeTextEditEntered();
    // TODO: Added if condition for ts potentially undefined variable warning
    const cloneHiddenTextarea = this.clone?.hiddenTextarea;
    if (cloneHiddenTextarea) {
      cloneHiddenTextarea.addEventListener('paste', (event) => {
        void this.onPasteText(event);
      });
      cloneHiddenTextarea.addEventListener('input', this.onTextInput.bind(this));
    }

    this.clone.on('changed', () => {
      this.onCanvasTextChange(this.clone?.text);
    });
    this.clone.on('selection:changed', this.onSelectionChanged.bind(this));
  }

  private onTextInput(): void {
    setTimeout(() => {
      this.page.canvasPanOnTextEdit.scrollCanvasToText();
    }, 150);
  }

  public onSelectionChanged(): void {
    if (shouldShowTextSelectionPopup(this.clone)) {
      handleTextSelectionPopupMenu(this.clone);
    } else if (getIsSpellCheckEnabledFromStore() && this.clone) {
      void this.page?.spellCheck?.handleSuggestionPopupMenu(this.clone);
    } else {
      closeTextSelectionPopup();
    }
  }

  public async onPasteText(e?: Event): Promise<void> {
    if (await getIsClipboardReadSupported()) {
      e?.preventDefault();
    }
    const clipText = await getTextFromClipboard();
    if (this.clone && clipText) {
      const validatedText = validateText(clipText);
      const updatedSelection = this.clone.selectionStart;
      pastedTextListType = await getListTypeFromPastedText();
      const prevText = this.clone.text;
      const updatedText = prevText.substring(0, this.clone.selectionStart) + validatedText + prevText.substring(this.clone.selectionEnd, prevText.length);
      this.clone.selectionStart = updatedSelection + validatedText.length;
      this.clone.selectionEnd = this.clone.selectionStart;
      if (this.clone.hiddenTextarea) {
        this.clone.hiddenTextarea.selectionEnd = this.clone.selectionEnd;
      }
      this.onCanvasTextChange(updatedText);
    }
  }

  public onCanvasTextChange(text = ''): void {
    if (this.textChangedTimeout > 0) {
      window.clearTimeout(this.textChangedTimeout);
    }

    if (this.clone) {
      const cloneDimensions = {
        width: this.clone.width + this.clone.strokeWidth,
        height: this.clone.height + this.clone.strokeWidth,
      };
      const heightWithoutVerticalPadding = this.clone.height + this.clone.strokeWidth + getScaledManualVerticalPadding(this.fabricObject.scaleY);
      const newVerticalPadding = Math.min(Math.max(this.fabricObject.height - heightWithoutVerticalPadding, 0), this.verticalPadding);
      // caching these 2 variables before updating group dimensions as they are needed in the next step.
      const cloneCenterPoint = this.clone.getCenterPoint();
      const groupBottomLeft = this.fabricObject.aCoords.bl;

      this.clone.width = Math.max(this.clone.dynamicMinWidth ?? 0, this.baseWidth);

      if (pastedTextListType && !this.list?.type) {
        const updatedList = this.getDefaultList();
        updatedList.type = pastedTextListType;
        this.list = updatedList;
      }
      // spm_.hideSelectionTextPopUp();
      this.setBulletPoints(true);
      this.updateGroupDimensions(cloneDimensions, newVerticalPadding);

      const newModelCoords = this.getUpdatedModelCoords(cloneCenterPoint, groupBottomLeft, this.clone);
      this.fabricObject.set({
        left: newModelCoords?.x,
        top: newModelCoords?.y,
      });
      this.setBulletsPosition();
      this.clone.set(this.getTextCloneAbsolutePosition(this.clone));
      this.setBackgroundParams();

      this.textChangedTimeout = window.setTimeout(this.updateText.bind(this, text, newModelCoords, newVerticalPadding), 500);
      // if (postermywall.Core.isMobileOS) {
      //     // this is needed because the model is only being updated after timeout for when editing is done through canvas.
      //     this.facade.sendNotification(postermywall.Core.UPDATE_TEXT_ITEM_TEXTAREA, {text: text});
      // }
    }
  }

  public updateText(
    text = '',
    modelNewCoordinates: ItemCoordinates = {
      x: this.x,
      y: this.y,
    },
    newVerticalPadding = this.verticalPadding
  ): void {
    void this.updateFromObject({
      text,
      width: this.fabricObject.width,
      height: this.fabricObject.height,
      verticalPadding: newVerticalPadding,
      x: modelNewCoordinates.x,
      y: modelNewCoordinates.y,
      textStyles: this.textStyles.getLanguageScriptAndFontForText(text),
    });
  }

  public updateActiveText(text = ''): void {
    this.updateText(text);
  }

  public getUpdatedModelCoords(cloneCenterPoint: FabricPoint, groupBottomLeft: Point, clone: Textbox): ItemCoordinates {
    switch (this.verticalAlign) {
      case TextVerticalAlignType.TOP:
        return this.getCoordsForTopVerticalAlign();

      case TextVerticalAlignType.CENTER:
        return this.getCoordsForCenterVerticalAlign(cloneCenterPoint, clone);

      case TextVerticalAlignType.BOTTOM:
        return this.getCoordsForBottomVerticalAlign(groupBottomLeft);

      default:
        throw new Error(`Invalid text align type`);
    }
  }

  /**
   * Returns the coordinates to be used to update model when the text is vertically top aligned.
   */
  protected getCoordsForTopVerticalAlign(): ItemCoordinates {
    const parentViewComponent = this.hasParentGroupGraphicItem() ? this.fabricObject.group : this.fabricObject;

    if (parentViewComponent) {
      const centerPoint = parentViewComponent.getCenterPoint();
      const theta = util.degreesToRadians(parentViewComponent.angle ?? 0);

      return {
        x:
          centerPoint.x -
          ((this.fabricObject.width * parentViewComponent.scaleX) / 2) * Math.cos(theta) +
          ((this.fabricObject.height * parentViewComponent.scaleY) / 2) * Math.sin(theta),
        y:
          centerPoint.y -
          ((this.fabricObject.width * parentViewComponent.scaleY) / 2) * Math.sin(theta) -
          ((this.fabricObject.height * parentViewComponent.scaleY) / 2) * Math.cos(theta),
      };
    }
    return {};
  }

  /**
   * Returns the coordinates to be used to update model when the text is vertically center aligned.
   */
  protected getCoordsForCenterVerticalAlign(cloneCenterPoint: FabricPoint, clone: Textbox): Point {
    const parentViewComponent = this.hasParentGroupGraphicItem() ? this.fabricObject.group ?? this.fabricObject : this.fabricObject;
    const vcWidth = this.fabricObject.width - this.getBulletsWidth(clone);
    const theta = util.degreesToRadians(parentViewComponent.angle);

    return {
      x:
        cloneCenterPoint.x -
        ((vcWidth * parentViewComponent.scaleX) / 2) * Math.cos(theta) +
        ((this.fabricObject.height * parentViewComponent.scaleY) / 2) * Math.sin(theta) -
        this.getBulletsWidth(clone) * parentViewComponent.scaleX * Math.cos(theta),
      y:
        cloneCenterPoint.y -
        ((vcWidth * parentViewComponent.scaleY) / 2) * Math.sin(theta) -
        ((this.fabricObject.height * parentViewComponent.scaleY) / 2) * Math.cos(theta) -
        this.getBulletsWidth(clone) * parentViewComponent.scaleY * Math.sin(theta),
    };
  }

  /**
   * Returns the coordinates to be used to update model when the text is vertically bottom aligned.
   * @param {Object} blCoords. The bottom left coordinate of group fabricObject before it's dimensions were updated.
   * @return {Object}
   * @private
   */
  protected getCoordsForBottomVerticalAlign(blCoords: Point): Point {
    const parentViewComponent = this.hasParentGroupGraphicItem() ? this.fabricObject.group ?? this.fabricObject : this.fabricObject;
    const theta = degreesToRadians(parentViewComponent.angle);

    return {
      x: blCoords.x + this.fabricObject.height * parentViewComponent.scaleX * Math.sin(theta),
      y: blCoords.y - this.fabricObject.height * parentViewComponent.scaleY * Math.cos(theta),
    };
  }

  protected updateGroupDimensions(cloneDimensions: FabricItemDimensions, newVerticalPadding: number): void {
    this.fabricObject.set({
      height: cloneDimensions.height + getScaledManualVerticalPadding(this.fabricObject.scaleY) + newVerticalPadding,
      width: cloneDimensions.width + getScaledManualHorizontalPadding(this.fabricObject.scaleX) + this.getBulletsWidth(this.clone),
    });
  }

  protected setCloneProperties(): void {
    if (this.clone !== null) {
      if (this.clone.hiddenTextarea) {
        this.clone.hiddenTextarea.value = this.fabricTextbox.text ?? '';
      }

      this.clone.set('text', this.text);
      this.clone.set(this.textStyles.getTextStyles(this.clone.width, this.clone.height));

      const options = {
        ...this.getCommonOptions(),
        ...this.getPropertiesForClone(),
      };

      this.clone.set(options);
      // this.applyFontVariation(this.clone);
      this.applyTextStroke(this.clone);
      this.clone.set(this.getTextCloneAbsolutePosition(this.clone));
      this.page.fabricCanvas.moveObjectTo(this.clone, this.page.fabricCanvas.getObjects().indexOf(this.fabricObject) + 1);
      this.clone.setCoords();
    }
  }

  protected getPropertiesForClone(): Record<string, any> {
    return {
      _fontSizeFraction: this.fabricTextbox._fontSizeFraction,
      cacheExpansionFactor: this.fabricTextbox.cacheExpansionFactor,

      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      hasControls: false,

      cursorColor: this.getCursorColor(),
      cursorWidth: this.fabricTextbox.cursorWidth,
      fontSize: this.fabricTextbox.fontSize,
      width: this.fabricTextbox.width,
      fill: this.fabricTextbox.fill,
      shadow: this.fabricTextbox.shadow,
      dirty: true,
    };
  }

  public doesfabricObjBelongtoItem(fabricObj: FabricObject | Group): boolean {
    return this.fabricObject === fabricObj || this.fabricTextbox === fabricObj || this.clone === fabricObj;
  }

  protected onTextEditModeEntered(): void {
    if (this.clone && this.clone.hiddenTextarea) {
      this.clone.hiddenTextarea.setAttribute('maxlength', MAX_CHARACTERS_LIMIT);
      this.page?.spellCheck?.checkSpell(this.clone, true).catch(() => {});
    }
  }

  protected onEditingExited(): void {
    if (this.clone) {
      this.page.fabricCanvas.remove(this.clone);
      this.fabricTextbox.set({opacity: 1});
      this.clone = null;
      this.page?.spellCheck?.closeSuggestionPopUp();
      closeTextSelectionPopup();
      this.page.canvasPanOnTextEdit.afterTextEditExited();
    }
  }

  public restoreDefaultState(): void {
    if (this.clone) {
      this.clone.exitEditing();
      this.page.activeSelection.setActiveObject(this.fabricObject as unknown as FabricObject);
    }
  }

  /**
   * Gets the position of the clone's fabricObject according to the vertical alignment set by the user.
   * Position is either clone's vc absolute position if called before bullets are added or bullet's vc absolute position if called after bullets are added
   */
  public getTextChildAbsolutePosition(cloneViewComponent: FabricObject): Record<string, any> {
    const parentViewComponent = this.hasParentGroupGraphicItem() ? this.fabricObject.group ?? this.fabricObject : this.fabricObject;
    const cornerPoints = cloneViewComponent.getCornerPoints(parentViewComponent.getCenterPoint());
    let theta;
    let displacement;
    let verticalParams: ClonePositionalParams;

    if (parentViewComponent) {
      parentViewComponent.setCoords();
    }

    switch (this.verticalAlign) {
      case TextVerticalAlignType.TOP:
        theta = degreesToRadians(45 + parentViewComponent.angle);
        displacement = Math.sqrt(2 * ITEM_CONTROL_DIMENSIONS.PMW_ITEM_LEGACY_PADDING ** 2);

        verticalParams = {
          top: parentViewComponent.aCoords.tl.y + displacement * Math.sin(theta),
          left: parentViewComponent.aCoords.tl.x + displacement * Math.cos(theta),
        };
        break;

      case TextVerticalAlignType.CENTER:
        verticalParams = {
          top: (cornerPoints.tl.y + cornerPoints.bl.y) / 2,
          left: (cornerPoints.tl.x + cornerPoints.bl.x) / 2,
        };
        break;

      case TextVerticalAlignType.BOTTOM:
        theta = degreesToRadians(45 + parentViewComponent.angle);
        displacement = Math.sqrt(2 * ITEM_CONTROL_DIMENSIONS.PMW_ITEM_LEGACY_PADDING ** 2);

        verticalParams = {
          top: parentViewComponent.aCoords.bl.y - displacement * Math.cos(theta),
          left: parentViewComponent.aCoords.bl.x + displacement * Math.sin(theta),
        };
        break;

      default:
        throw new Error(`Invalid text align type`);
    }

    verticalParams.originY = this.verticalAlign;
    return verticalParams;
  }

  public getPaddedTextChildAbsolutePosition(cloneViewComponent: FabricObject): Record<string, any> {
    const parentViewComponent = this.hasParentGroupGraphicItem() ? this.fabricObject.group ?? this.fabricObject : this.fabricObject;
    const cornerPoints = cloneViewComponent.getCornerPoints(parentViewComponent.getCenterPoint());
    let verticalParams: ClonePositionalParams;

    if (parentViewComponent) {
      parentViewComponent.setCoords();
    }

    switch (this.verticalAlign) {
      case TextVerticalAlignType.TOP:
        verticalParams = {
          top: parentViewComponent.aCoords.tl.y,
          left: parentViewComponent.aCoords.tl.x,
        };
        break;

      case TextVerticalAlignType.CENTER:
        verticalParams = {
          top: (cornerPoints.tl.y + cornerPoints.bl.y) / 2,
          left: (cornerPoints.tl.x + cornerPoints.bl.x) / 2,
        };
        break;

      case TextVerticalAlignType.BOTTOM:
        verticalParams = {
          top: parentViewComponent.aCoords.bl.y,
          left: parentViewComponent.aCoords.bl.x,
        };
        break;

      default:
        throw new Error(`Invalid text align type`);
    }

    verticalParams.originY = this.verticalAlign;
    return verticalParams;
  }

  /**
   * Gets the position of the clone's fabricObject according to the vertical alignment after adding bullets.
   */
  public getTextCloneAbsolutePosition(cloneViewComponent: Textbox): Record<string, any> {
    const parentViewComponent = this.hasParentGroupGraphicItem() ? this.fabricObject.group ?? this.fabricObject : this.fabricObject;
    const verticalParams = this.getTextChildAbsolutePosition(cloneViewComponent);
    const bulletsAngle = degreesToRadians(90 - parentViewComponent.angle);
    const bulletsWidth = this.getBulletsWidth(cloneViewComponent) * parentViewComponent.scaleX;

    if (this.verticalAlign === TextVerticalAlignType.CENTER) {
      verticalParams.top += (bulletsWidth * Math.cos(bulletsAngle)) / 2;
      verticalParams.left += (bulletsWidth * Math.sin(bulletsAngle)) / 2;
    } else {
      verticalParams.top += bulletsWidth * Math.cos(bulletsAngle);
      verticalParams.left += bulletsWidth * Math.sin(bulletsAngle);
    }

    verticalParams.originY = this.verticalAlign;
    return verticalParams;
  }

  public getMinWidth(): number {
    const scaleX = this.hasParentGroupGraphicItem() ? this.fabricObject.group?.scaleX ?? this.fabricObject.scaleX : this.fabricObject.scaleX;
    return this.fabricTextbox.getMinWidth() + this.getBulletsWidth() + this.fabricTextbox.strokeWidth + getScaledManualHorizontalPadding(scaleX);
  }

  public onModifyWidth(): void {
    this.updateTextChildWidth();
    this.setBulletPoints();
    this.setBulletsPosition();
    this.updateFabricGroupHeight();
    this.setTextChildPosition();
    this.setBackgroundParams();
  }

  public updateTextChildWidth(): void {
    this.fabricTextbox.set({
      width: this.calculateBaseWidth(),
    });
  }

  protected calculateGroupWidth(): number {
    const scaleX = getCumulativeVCScaleX(this.fabricObject);
    return this.getTextStrokedWidth() + getScaledManualHorizontalPadding(scaleX) + this.getBulletsWidth();
  }

  protected calculateGroupHeight(): number {
    const scaleY = getCumulativeVCScaleY(this.fabricObject);
    return this.getTextStrokedHeight() + getScaledManualVerticalPadding(scaleY) + this.verticalPadding;
  }

  protected calculateBaseWidth(): number {
    const scaleX = getCumulativeVCScaleX(this.fabricObject);
    return this.fabricObject.width - this.getBulletsWidth() - getScaledManualHorizontalPadding(scaleX) - this.getStrokeWidth();
  }

  protected getStrokeWidth(): number {
    if (this.textStyles.isBold && !isBoldVariationAvaliableForFont(this.textStyles.fontFamily)) {
      return getBoldStrokeWidth(this);
    }
    if (this.textStyles.stroke) {
      return this.getOutlineStrokeWidth();
    }
    return DEFAULT_STROKE_WIDTH;
  }

  protected getOutlineStrokeWidth(): number {
    return TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.textStyles.fontSize * this.textStyles.strokeWidth;
  }

  public getManualSelectorPadding(): number {
    return ITEM_CONTROL_DIMENSIONS.PMW_ITEM_LEGACY_PADDING;
  }

  protected updateFabricGroupHeight(): void {
    this.fabricObject.height = this.calculateGroupHeight();
  }

  protected updateFabricGroupWidth(): void {
    this.fabricObject.width = this.calculateGroupWidth();
  }

  protected getTextStrokedWidth(): number {
    return this.fabricTextbox.width + this.fabricTextbox.strokeWidth;
  }

  public getTextStrokedHeight(): number {
    return this.fabricTextbox.height + this.fabricTextbox.strokeWidth;
  }

  public setTextChildPosition(): void {
    this.setTextTopPositionByVerticalAlignment();
    this.setTextLeftPosition();
  }

  protected setTextTopPositionByVerticalAlignment(): void {
    const scaleY = getCumulativeVCScaleY(this.fabricObject);

    switch (this.verticalAlign) {
      case TextVerticalAlignType.TOP:
        this.fabricTextbox.set({
          top: -this.fabricObject.height / 2 + getScaledManualTopPadding(scaleY),
        });
        break;

      case TextVerticalAlignType.CENTER:
        this.fabricTextbox.set({
          top: -this.getTextStrokedHeight() / 2,
        });
        break;

      case TextVerticalAlignType.BOTTOM:
        this.fabricTextbox.set({
          top: this.fabricObject.height / 2 - getScaledManualTopPadding(scaleY) - this.getTextStrokedHeight(),
        });
        break;

      default:
        throw new Error(`Invalid text align type`);
    }
  }

  protected setTextLeftPosition(): void {
    const scaleX = getCumulativeVCScaleX(this.fabricObject);

    this.fabricTextbox.set({
      left: -this.fabricObject.width / 2 + this.getBulletsWidth() + getScaledManualLeftPadding(scaleX),
    });
  }

  public getMinHeight(): number {
    const scaleY = this.hasParentGroupGraphicItem() ? this.fabricObject.group?.scaleY ?? this.fabricObject.scaleY : this.fabricObject.scaleY;
    return this.getTextStrokedHeight() + getScaledManualVerticalPadding(scaleY);
  }

  public onModifyHeight(): void {
    this.setBulletsPosition();
    this.setTextChildPosition();
    this.setBackgroundParams();
  }

  public getVerticalPadding(): number {
    const scaleY = this.hasParentGroupGraphicItem() ? this.fabricObject.group?.scaleY ?? this.fabricObject.scaleY : this.fabricObject.scaleY;
    return this.fabricObject.height - this.getTextStrokedHeight() - getScaledManualVerticalPadding(scaleY);
  }

  public setBulletPoints(useClone = false): void {
    if (this.fabricBulletTextbox) {
      this.fabricObject.remove(this.fabricBulletTextbox);
      this.fabricBulletTextbox = null;
    }
    if (this.list.type) {
      this.fabricBulletTextbox = new Textbox('', {});
      this.addBulletToGroupWithOriginalScale(this.fabricBulletTextbox);
      this.fabricBulletTextbox.__PMWID = this.uid;
      this.setBulletsProperties(this.fabricBulletTextbox);
      this.setBulletsText(this.fabricBulletTextbox, useClone, this.shouldBulletsObjectHaveText());
      this.applyBulletColor();
      if (!this.shouldBulletsObjectHaveText()) {
        this.fabricBulletTextbox.set({width: this.fabricBulletTextbox.calcTextWidth()});
      }
    }
  }

  /**
   * Fabric sets the scales of add item such that the final scale inculding its group equals to that
   * We don't want that for layouts so set group scale 1 so that the add items scale doesn't change and
   * then restore the group scale
   */
  addBulletToGroupWithOriginalScale(bulletFabricObject: FabricObject): void {
    addItemsToGroupWithOriginalScale(this.fabricObject, [bulletFabricObject]);
    this.fabricObject.moveObjectTo(bulletFabricObject, 2);
  }

  private setBulletsProperties(bulletTextbox: Textbox): void {
    const opts = this.getBulletsProperties();
    // @ts-ignore TODO: Remove this when TextSlide is added to this type. // TODO: make function for this line that exists in text item and text slide item.
    const fabricTextItem: Textbox = this.slideshow ? this.slideshow.clone ?? this.fabricTextbox : this.clone ?? this.fabricTextbox;
    const bulletClone = new Textbox('', {});

    bulletTextbox.set(opts);
    bulletClone.set(opts);
    bulletTextbox.set('shadow', this.getShadow());
    this.setBulletsText(bulletClone);
    if (this.list.isOrdered()) {
      this.applyFontVariationForBullets();
    }
    if (this.shouldBulletsObjectHaveText()) {
      bulletTextbox.set('width', fabricTextItem.width + bulletClone.calcTextWidth());
    }
  }

  private setBulletsText(bulletTextbox: Textbox, useClone = false, addTransparentText = false): void {
    let fabricTextItem: Textbox;
    // @ts-expect-error TODO: Remove this when TextSlide is added to this type
    if (this.slideshow) {
      // @ts-expect-error TODO: Remove this when TextSlide is added to this type
      fabricTextItem = useClone && this.slideshow.clone ? this.slideshow.clone : this.fabricTextbox;
    } else if (useClone && this.clone) {
      fabricTextItem = this.clone;
    } else {
      fabricTextItem = this.fabricTextbox;
    }
    let listCounter = 1;
    let isWrapping = false;
    let text = '';
    for (let i = 0; i < fabricTextItem._textLines.length; i++) {
      let start = text.length;
      if (!isWrapping) {
        text += this.getBulletTextboxLineText(listCounter);
        start = text.length;
        text += addTransparentText ? fabricTextItem.textLines[i] : '';
        isWrapping = !fabricTextItem.isEndOfWrapping(i);
        if (!isWrapping) listCounter += 1;
      } else if (fabricTextItem.isEndOfWrapping(i)) {
        isWrapping = false;
        listCounter += 1;
      }
      if (fabricTextItem._textLines.length !== i + 1) {
        text += '\n';
      }
      bulletTextbox.set('text', text);
      if (this.list.isUnordered()) {
        bulletTextbox.setSelectionStyles({fontFamily: 'bullets'}, start - 1, start);
      }
      if (this.shouldBulletsObjectHaveText()) {
        bulletTextbox.setSelectionStyles({fill: 'rgba(0,0,0,0)'}, start, text.length);
        bulletTextbox.setSelectionStyles({stroke: 'rgba(0,0,0,0)'}, start, text.length);
      }
    }
  }

  private getBulletTextboxLineText(listCounter: number): string {
    if (this.list) {
      if (this.list.isUnordered() && UNORDERED_BULLETS_ASCII_MAP.has(this.list.style)) {
        return hexToAscii(UNORDERED_BULLETS_ASCII_MAP.get(this.list.style) ?? '');
      }
      return `${this.getOrderedBulletStyle(listCounter)}.`;
    }
    return '';
  }

  private getOrderedBulletStyle(listCounter: number): string {
    switch (this.list.style) {
      case ORDERED_LIST_STYLE.NUMBERED_DECIMAL:
        return listCounter.toString();
      case ORDERED_LIST_STYLE.NUMBERED:
        return listCounter < 10 ? `0${listCounter}` : listCounter.toString();
      case ORDERED_LIST_STYLE.ALPHA_UPPER:
        if (listCounter % TOTAL_ALPHA_COUNT !== 0) {
          let char1 = String.fromCharCode('@'.charCodeAt(0) + (listCounter % TOTAL_ALPHA_COUNT));
          char1 = Math.floor(listCounter / TOTAL_ALPHA_COUNT) > 0 ? char1.repeat(Math.floor(listCounter / TOTAL_ALPHA_COUNT) + 1) : char1;
          return char1;
        }
        return String.fromCharCode('@'.charCodeAt(0) + TOTAL_ALPHA_COUNT).repeat(Math.floor(listCounter / TOTAL_ALPHA_COUNT));
      case ORDERED_LIST_STYLE.ALPHA_LOWER:
        if (listCounter % TOTAL_ALPHA_COUNT !== 0) {
          let char2 = String.fromCharCode('`'.charCodeAt(0) + (listCounter % TOTAL_ALPHA_COUNT));
          char2 = Math.floor(listCounter / TOTAL_ALPHA_COUNT) > 0 ? char2.repeat(Math.floor(listCounter / TOTAL_ALPHA_COUNT) + 1) : char2;
          return char2;
        }
        return String.fromCharCode('`'.charCodeAt(0) + TOTAL_ALPHA_COUNT).repeat(Math.floor(listCounter / TOTAL_ALPHA_COUNT));
      case ORDERED_LIST_STYLE.ROMAN_UPPER:
        return integerToRoman(listCounter);
      case ORDERED_LIST_STYLE.ROMAN_LOWER:
        return integerToRoman(listCounter).toLowerCase();

      default:
        throw new Error(`Invalid ordered bullet style: ${this.list.style}`);
    }
  }

  private getBulletsProperties(): Record<string, any> {
    return {
      lineHeight: this.fabricTextbox.lineHeight,
      opacity: this.alpha,
      editable: false,
      fontSize: this.fabricTextbox.fontSize,
      fontFamily: this.fabricTextbox.fontFamily,
      _fontSizeFraction: this.fabricTextbox._fontSizeFraction,
      textAlign: this.shouldBulletsObjectHaveText() ? this.textStyles.textAlign : TextHorizontalAlignType.RIGHT,
    };
  }

  private shouldBulletsObjectHaveText(): boolean {
    return this.hasCenterHorizontalAlignment() || this.hasRightHorizontalAlignment();
  }

  private applyFontVariationForBullets(): void {
    if (this.fabricBulletTextbox) {
      const fontFamilyWithVariation = getFontFamilyNameForVariations(this.textStyles.fontFamily, this.textStyles.isBold, this.textStyles.isItalic);
      const isFontLoaded = fontsRequestedMap[fontFamilyWithVariation] === FONT_LOAD_STATUS.LOADED;
      const isBoldApplied = isFontLoaded ? isBoldVariationAvaliableForFont(this.textStyles.fontFamily) : false;
      const isItalicApplied = isFontLoaded ? isItalicVariationAvaliableForFont(this.textStyles.fontFamily) : false;

      this.fabricBulletTextbox.set('fontStyle', this.textStyles.isItalic && !isItalicApplied ? FONT_VARIATION.ITALIC : DEFAULT_FONT_STYLE);
      if (this.list && this.textStyles.isBold && !isBoldApplied) {
        let gradientOpts;
        let color;
        if (this.list.hasLinearGradientBulletFill()) {
          gradientOpts = getLinearGradientOpts(this.fabricBulletTextbox.height, this.list.fill.fillColor);
          color = new Gradient(gradientOpts);
        } else if (this.list.hasRadialGradientBulletFill()) {
          gradientOpts = getRadialGradientOpts(this.fabricBulletTextbox.width, this.fabricBulletTextbox.height, this.list.fill.fillColor);
          color = new Gradient(gradientOpts);
        } else {
          color = rgbToHexString(this.list.fill.fillColor[0], 1);
        }

        this.fabricBulletTextbox.set('stroke', color);
        this.fabricBulletTextbox.set('strokeLineJoin', 'round');
        this.fabricBulletTextbox.set('strokeWidth', getBoldStrokeWidth(this));
      } else {
        this.fabricBulletTextbox.set('strokeWidth', DEFAULT_STROKE_WIDTH);
        this.fabricBulletTextbox.set('strokeLineJoin', 'miter');
        this.fabricBulletTextbox.set('stroke', undefined);
      }
    }
  }

  private applyBulletColor(): void {
    if (this.list && this.fabricBulletTextbox) {
      const fabricTextItem = this.clone ?? this.fabricTextbox;
      if (this.list.hasLinearGradientBulletFill()) {
        this.fabricBulletTextbox.set('fill', new Gradient(getLinearGradientOpts(fabricTextItem.height, this.list.fill.fillColor)));
      } else if (this.list.hasRadialGradientBulletFill()) {
        this.fabricBulletTextbox.set('fill', new Gradient(getRadialGradientOpts(fabricTextItem.width, fabricTextItem.height, this.list.fill.fillColor)));
      } else {
        this.fabricBulletTextbox.set('fill', rgbToHexString(this.list.fill.fillColor[0], 1));
      }
    }
  }

  public setBulletsPosition(clone = this.clone): void {
    if (this.fabricBulletTextbox) {
      const getStrokedHeight = (): number => {
        const fabricTextItem = clone ?? this.fabricTextbox;
        return fabricTextItem.height + fabricTextItem.strokeWidth;
      };

      const scaleX = getCumulativeVCScaleX(this.fabricObject);
      const scaleY = getCumulativeVCScaleY(this.fabricObject);
      let top;

      this.fabricBulletTextbox.set('left', -this.fabricObject.width / 2 + getScaledManualLeftPadding(scaleX));

      switch (this.verticalAlign) {
        case TextVerticalAlignType.TOP:
          top = -this.fabricObject.height / 2 + getScaledManualTopPadding(scaleY);
          break;

        case TextVerticalAlignType.BOTTOM:
          top = this.fabricObject.height / 2 - getScaledManualTopPadding(scaleY) - getStrokedHeight();
          break;

        case TextVerticalAlignType.CENTER:
          top = -getStrokedHeight() / 2;
          break;

        default:
          throw new Error(`Invalid text align type`);
      }
      top -= this.list.isUnordered() ? this.textStyles.fontSize / BULLETS_TOP_DISTANCE_FACTOR : 0;
      this.fabricBulletTextbox.set('top', top);
      this.fabricBulletTextbox.setCoords();
    }
  }

  public getBulletsWidth(clone?: Textbox | null): number {
    let width = 0;
    const fabricTextItem = clone || this.fabricTextbox;
    if (this.fabricBulletTextbox) {
      width = this.fabricBulletTextbox.width + this.textStyles.fontSize / BULLETS_RIGHT_DISTANCE_FACTOR;
      width -= this.shouldBulletsObjectHaveText() ? fabricTextItem.width : 0;
    }
    return width;
  }

  public getFill(): string | TFiller | null {
    return this.fabricTextbox.fill;
  }

  public hasEditMode(): boolean {
    return true;
  }

  public getDefaultList(): TextList {
    const updatedList = new TextList(this.list.toObject());
    updatedList.type = LIST_TYPES.UNORDERED;
    updatedList.style = UNORDERED_LIST_STYLE.CIRCLE_1;
    updatedList.fill = this.list.fill;
    return updatedList;
  }

  public getColors(): Array<RGB> {
    let colors: Array<RGB> = super.getColors();

    if (this.textStyles.fill.hasFill()) {
      colors = [...colors, ...this.textStyles.fill.fillColor];
    }

    if (this.hasBackground()) {
      colors = [...colors, ...this.background.fill.fillColor];
    }

    return colors;
  }
}

export const addTextToPoster = (): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  void currentPage.items.addItems.addTextItem({
    type: ElementDataType.TEXT,
  });
};

export const getTextFromClipboard = async (): Promise<string | undefined> => {
  let clipText;

  try {
    clipText = await readFromClipboard();
  } catch (err) {
    return undefined;
  }

  try {
    const data: unknown = JSON.parse(clipText);
    if (typeof data === 'object') {
      const validateditemsAndPoster = getValidatedItemsAndPosterClipboardData(data);
      if (validateditemsAndPoster) {
        if (validateditemsAndPoster.graphicItemObjects.length === 1 && validateditemsAndPoster.graphicItemObjects[0].gitype === ITEM_TYPE.TEXT) {
          const textItemObject = validateditemsAndPoster.graphicItemObjects[0] as TextItemObject;
          return textItemObject.text;
        }
      }
      return undefined;
    }
  } catch (err) {
    return clipText;
  }
  return clipText;
};

/**
 * FONTSIZE_FRACTION is a hash map where the key is the font family, this is used to assign a value to _fontSizeFraction
 * when the poster made in flash is opened in HTML5 builder.
 */
export const FONTSIZE_FRACTION = {
  AccidentalPresidency: 0.18,
  Acknowledgement: 0.3,
  AdventProRegular: 0.15,
  AgentOrange: 0.285,
  AlexBrushRegular: 0.285,
  AnimeAce: 0.27,
  AnuDaw: 0.235,
  ArbuckleRemix: 0.38,
  ArchivoBlackRegular: 0.39,
  AsapRegular: 0.18,
  AYummyApology: 0.39,
  Bangers: 0.22,
  BerkshireSwashRegular: 0.12,
  BigApple: 0.31,
  Blackout2am: 0.35,
  BlackoutMidnight: 0.28,
  BlackRose: 0.21,
  BostonTraffic: 0.29,
  BowlbyOneSCRegular: 0.04,
  BubbleBoy2: 0,
  BurnstownDam: 0.42,
  CACChampagne: 0.34,
  Caeldera: 0.39,
  Chancery: 0,
  Chopin: 0,
  Chumbly: 0.45,
  Coda: 0.28,
  ComingSoon: 0.35,
  Concielian: 0.42,
  DayPosterBlack: 0.02,
  DigitalDream: 0.34,
  Effloresce: 0.29,
  EraserRegular: 0.15,
  FingerPaintRegular: 0.02,
  FrijoleRegular: 0.1,
  FugazOneRegular: 0.08,
  GermaniaOneRegular: 0.22,
  HamburgerHeaven: 0.18,
  HennyPennyRegular: 0.0,
  HoltwoodOneSC: -0.08,
  JockeyOneRegular: 0.05,
  JosefinSlabRegular: 0.39,
  KaushanScriptRegular: 0.03,
  LeagueSpartanBold: 0.38,
  LemonRegular: 0.09,
  LiberationSerif: 0.42,
  Liquidism: 0.34,
  LoveYaLikeASister: 0.2,
  MerriweatherSansRegular: 0.15,
  MetalLord: 0.38,
  MetalManiaRegular: 0.19,
  Monoton: -0.05,
  MountainsofChristmas: 0.2,
  MouseMemoirsRegular: 0.18,
  NixieOneRegular: 0.18,
  Oklahoma: 0.1,
  OldStandardRegular: 0.36,
  OpenSansRegular: 0.36,
  OperatingInstructions: 0.36,
  OriginalSurferRegular: 0.14,
  PaprikaRegular: -0.03,
  PompiereRegular: 0.19,
  Porter: 0.38,
  Primitive: 0.2,
  SacramentoRegular: 0.2,
  SancreekRegular: 0.09,
  SevenMonkeyFury: 0.23,
  SFCollegiate: 0.34,
  SFSpeedwaystar: 0.45,
  ShadowsIntoLightTwoRegular: 0.02,
  Shermlock: 0.065,
  SirinStencilRegular: -0.05,
  SixCaps: 0.025,
  SmokumRegular: 0.14,
  SoftSugar: 0.325,
  Solov2: 0.42,
  SpecialElite: 0.42,
  SpiraxRegular: 0.19,
  Sveningsson: 0.19,
  TagsXtreme: 0.36,
  Titania: 0.22,
  TulpenOneRegular: 0.25,
  Ultra: 0.12,
  WaitingfortheSunrise: 0.0,
  Windsong: 0.32,
};

/**
 * A hash map that contains the expansion factor for text's canvas cache. It only contains factor for fonts which get cut off with fabric's default behavior (default text's canvas cache dimensions).
 */
export const CUSTOM_CACHE_EXPANSION_FACTOR = {
  AmsterdamOne: 2.6,
};
